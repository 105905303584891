import { matchRoutes, useLocation } from "react-router-dom";

export const routes = {
  root: "/:brand/:locale",
  home: "/:brand/home/:locale",
  confirmation: "/:brand/confirmation/:locale",
};

export const routesBase = {
  root: "/:brand",
  home: "/:brand/home",
  confirmation: "/:brand/confirmation"
};

const routerRoutes = Object.values(routes).map((path) => ({ path }));

export function usePathPattern() {
  const location = useLocation();
  const match = matchRoutes(routerRoutes, location);
  if (match) {
    return match[0]?.route?.path;
  }
  return routes.root;
}
