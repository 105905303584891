import React, { useEffect } from "react";
import {Trans, useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import logo from "../images/logos/wchd/color.png";
import paxlovid from "../images/paxlovid.jpg";
import vaccination from "../images/vaccination.jpg";
import tests from "../images/covid_home_tests.jpg";
import demographics from "../images/demographics.jpg";
import MyDataHelps from "@careevolution/mydatahelps-js"
import {
	useSearchParams
} from "react-router-dom";
import Footer from "../partials/footer";
import Nav from "../partials/nav";
import Attention from "../partials/attention";

export default function Home() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};


	const VaxUrl = "https://www.washtenaw.org/3269/COVID-19-Vaccination"


	var kitsAvailable = search.get("kitsAvailable") === "true";
	var demographicsSurvey = search.get("demographicsSurvey");

	function startRequest() {
		window.open((MyDataHelps.baseUrl ? MyDataHelps.baseUrl : "https://mydatahelps.org/") + "mydatahelps/" + search.get("participant") + "/surveylink/AntiviralTreatmentEvaluation");
	}

	function requestTests() {
		window.open((MyDataHelps.baseUrl ? MyDataHelps.baseUrl : "https://mydatahelps.org/") + "mydatahelps/" + search.get("participant") + "/surveylink/OrderCOVIDTests");
	}

	function launchDemographics() {
		MyDataHelps.startEmbeddedSurvey(search.get("participant"), "Demographics").then(function (result) {
			if (result.reason === "Completed") {
				window.location = window.location.origin + window.location.pathname + "?kitsAvailable=" + kitsAvailable + "&demographicsSurvey=true&participant=" + result.linkIdentifier;
			}
		});
	}

	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="confirmation-page">
				<section data-aos="zoom-y-out" className="center" style={{ padding: '0' }}>
					<div className="container fifty-50 col-1-sm">
						<div className="section-text">
							<img src={logo} alt="health department logo" style={{ maxWidth: '150px', margin: '0 auto', paddingBottom: '2rem' }} />
							<h3>{t("conf-h3a")}</h3>
							<h1>{t("conf-h1a")}<span style={{ fontWeight: '300' }}>{t("conf-h1b")}</span><span style={{ fontWeight: '800' }}>{t("conf-h1c")}</span><span style={{ fontWeight: '300' }}>{t("conf-h1d")}</span><span style={{ fontWeight: '800' }}>{t("conf-h1e")}</span></h1>
							<h3 style={{ maxWidth: '75rem', margin: '1rem auto 0 auto' }}>{t("conf-h3b")}</h3>
							{kitsAvailable &&
								<div>
									<p className="divider" style={{ maxWidth: '52rem', margin: '0 auto 0.75rem auto' }}>{t("conf-p1")}</p>
									<button style={{ maxWidth: '280px', margin: '1rem auto' }} className="secondary button" onClick={() => requestTests()}>{t("conf-p1-button")}</button>
								</div>
							}
							{demographicsSurvey !== "true" &&
								<>
									<p className="divider" style={{ maxWidth: '52rem', margin: '0 auto 0.75rem auto' }}><span><Trans i18nKey="conf-p2">We would appreciate it if you completed the following <strong>optional</strong> survey to help us learn more about how to best serve the community.</Trans></span></p>
									<button style={{ maxWidth: '280px', margin: '1rem auto' }} className="loud button" onClick={() => launchDemographics()}>{t("conf-p2-button")}</button>
								</>
							}
						</div>
					</div>
				</section>
				<section id="tested-positive" className="full-bleed" data-aos="zoom-y-out">
					<div className="dark-bg container col-2-sm">
						<div className="section-text">
							<h2>{t("conf-h2a")}</h2>
							<p>{t("conf-p3a")}<a target="_blank" rel="noreferrer" href="https://covid-19-test-to-treat-locator-dhhs.hub.arcgis.com/">{t("conf-p3b")}</a>{t("conf-p3c")}</p>
							<button className="button" onClick={() => startRequest()}>{t("conf-p3-button")}</button>
						</div>
						<div className="section-image">
							<img src={paxlovid} alt="paxlovid boxes" />
						</div>
					</div>
				</section>
				<section className="full-bleed" data-aos="zoom-y-out">
					<div className="highlighted container col-2-sm">
						<div className="section-image">
							<img src={tests} alt="paxlovid boxes" />
						</div>
						<div className="section-text">
							<h2>{t("conf-h2b")}</h2>
							<p><Trans i18nKey="conf-p4a"><strong>Treatment must be started within the first few days</strong> to be effective, so it's important to <strong>test ASAP</strong>. If you have any at-home tests, take one right away. If not, and you don't have symptoms yet or it's only the first day of symptoms, order tests online. Otherwise, call your healthcare provider, find a </Trans><a target="_blank" rel="noreferrer" href="https://covid-19-test-to-treat-locator-dhhs.hub.arcgis.com/">{t("conf-p3b")}</a>.</p>
							{kitsAvailable &&
								<button className="secondary button" onClick={() => requestTests()}>{t("conf-p1-button")}</button>
							}
							<button className="tertiary button" onClick={() => startRequest()}>{t("conf-p4-button")}</button>
						</div>
					</div>
				</section>
				{demographicsSurvey !== "true" &&
					<section className="full-bleed" data-aos="zoom-y-out">
						<div className="dark-bg container col-2-sm">
							<div className="section-text">
								<h2>{t("conf-h2c")}
								</h2>
								<p><Trans i18nKey="conf-p5">We would appreciate it if you would answer the following <strong>optional</strong> questions about yourself. This will help us improve our ability to serve the community.</Trans></p>
								<p><Trans i18nKey="conf-p6"><strong>Your response to this survey does not affect your ability to get testing and/or treatment.</strong></Trans></p>
								<button className="tertiary button" onClick={() => launchDemographics()}>{t("conf-p2-button")}</button>
							</div>
							<div className="section-image">
								<img src={demographics} alt="diverse group of masked people" />
							</div>
						</div>
					</section>
				}
				<section data-aos="zoom-y-out" className="callout-wrap">
					<div className="container full-bleed highlighted callout col-2-sm">
						<div className="section-text">
							<h2>{t("conf-h2d")}
							</h2>
							<ul>
								<li><span className="highlight">{t("conf-h2d-lia")}</span>{t("conf-h2d-lib")}</li>
								<li><a href={VaxUrl} target="_blank" rel="noreferrer">{t("conf-h2d-lic")}</a></li>
								<li>{t("conf-h2d-lid")}</li>
								<li>{t("conf-h2d-lie")}</li>
								<li>{t("conf-h2d-lif")}</li>
								<li>{t("conf-h2d-lig")}</li>
								<li>{t("conf-h2d-lih")}</li>
								<li>{t("conf-h2d-lii")}</li>
							</ul>
						</div>
						<div className="section-image hide-under-sm">
							<img src={vaccination} alt="free at-home covid tests" />
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	);
}
