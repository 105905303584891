import React from "react";
import whitelogo from "../images/logos/wchd/white_stacked.png";
import bug from "../images/logos/wchd/white_bug.svg";
import { useParams } from "react-router-dom";
import LanguageSwitcher from "./language-switcher";
import {Trans, useTranslation } from "react-i18next";


export default function Footer() {
  let { brand } = useParams();
  const { t, i18n } = useTranslation();

  return (
    <footer className="dark-bg">
      <div className="container">
        <div className="footer_left">
          { brand !== 'wchd' &&
           <div className="footer-logo" style={{ border: "none" }}>
              <img
              src={bug}
              alt="logo"
              style={{height: '36px', marginRight: '0.5rem' }}
              />
              <div>
                <span style={{fontWeight:'300'}}>{t("footer-intro-1")}</span><span style={{fontWeight:'800'}}>{t("footer-intro-2")}</span><span style={{fontWeight:'300'}}>{t("footer-intro-3")}</span><span style={{fontWeight:'800'}}>{t("footer-intro-4")}</span>
              </div>
           </div>
          }
          { brand === 'wchd' &&
            <>
              <div className="footer-logo">
              <img
                  src={whitelogo}
                  alt="home button"
                />
              </div>
              <div className="contact-info">
                {t("footer-contact-1")}<br></br>
                {t("footer-contact-2")}<br></br>
                {t("footer-contact-3a")}<a href="tel:734-544-6700">{t("footer-contact-3b")}</a><br></br>
                <a href="mailto:l-wchdcontact@washtenaw.org">{t("footer-contact-4")}</a>
              </div>
            </>
          }
        </div>
        <div className="footer_right">
          <LanguageSwitcher/>
          <p>© {(new Date().getFullYear())} CareEvolution, LLC.</p>
        </div>
      </div>
    </footer>
  );
}
