import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { routes } from "../routes";
import { useParams } from "react-router-dom";
import bug from "../images/logos/wchd/white_bug.svg";

export default function Nav() {
  let { brand } = useParams();
  const { t, i18n } = useTranslation();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const isConfPage = window.location.href.indexOf("confirmation") > -1

  function joinedModal() {
    closeModal()
    if (document.querySelector(".already-joined")) {
      document.querySelector(".already-joined").classList.remove("hide");
    }
  }

  function contactUsModal(){
    closeModal()
    if (document.querySelector(".contact-us")) {
      document.querySelector(".contact-us").classList.remove("hide");
    }
  }
  function closeModal() {
    document.querySelectorAll(".modal-overlay").forEach(function (el) {
      el.classList.add("hide");
    });
  }
  return (
    <>
        <nav
      className={`dark-bg main-navigation ${navbarOpen ? " toggled" : ""}`}
      role="navigation"
    >
      <div className="container">
      <div style={{display:'flex',justifyContent:'space-between'}}>
        <div id="site-header">
          <Link
            to={brand ? generatePath(routes.root, { brand: brand, locale: i18n.resolvedLanguage }) : "/"}
            rel="home"
          ><img
          src={bug}
          alt="logo"
          style={{height: '36px', marginRight: '0.5rem' }}
        /><span style={{fontWeight:'300'}}>{t("nav-intro-1")}</span><span style={{fontWeight:'800'}}>{t("nav-intro-2")}</span><span style={{fontWeight:'300'}}>{t("nav-intro-3")}</span><span style={{fontWeight:'800'}}>{t("nav-intro-4")}</span>
          </Link>
        </div>

        <div className="right-nav">
      </div>




      </div>
      </div>
    </nav>
    <div className="modal-overlay hide already-joined">
        <div className="modal-window">
          <h2 className="modal-header">
          {t("already-joined")}
            <div className="modal-close" onClick={closeModal}>
              <i className="fas fa-times">
                <span className="sr-only">{t("close")}</span>
              </i>
            </div>
          </h2>
          <div className="modal-body">
            <p>{t("nav-p1-a")}<a href="tel:978-389-3581">{t("nav-p1-b")}</a>.</p>
            <p>{t("nav-p2")}</p>
          </div>
        </div>
      </div>
    </>
  );
}
