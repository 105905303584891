import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import "./styles/fontawesome-free-6.1.1-web/css/all.css";
import "./styles/_main.css";

import { routes, routesBase } from "./routes";

import Layout from "./pages/layout";
import Home from "./pages/home";
import Lost from "./pages/404";
import Confirmation from "./pages/confirmation";
import faviconUrl from "./images/favicons/favicon.ico";
import favicon192Url from "./images/favicons/favicon-192.png";

import MyDataHelps from "@careevolution/mydatahelps-js"
import TagManager from "react-gtm-module";

if (process.env.REACT_APP_BUILD_ENV === "development") {
    MyDataHelps.setParticipantAccessToken({}, "https://mdhorg.ce.dev/");
}

if (process.env.REACT_APP_BUILD_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-TGXJL69",
  };
  TagManager.initialize(tagManagerArgs);
}

function ValidateRoutes() {
  let params = useParams();
  let brandsWhitelist = ["wchd"]
  let pathsWhitelist = ["home","confirmation","404"]
  let urlSegments = useLocation().pathname.split("/")
  const destination = urlSegments.filter(value => pathsWhitelist.includes(value));
  if(!(brandsWhitelist.indexOf(params.brand) > -1)){
    return <Lost />;
  }
  switch (destination[0]) {
    case "home": return <Home brand={params.brand} />;
    case "confirmation": return <Confirmation brand={params.brand} />;
    case "404": return <Lost />;
    default: return <Home brand={params.brand} />;
  }
}
const rtlLanguages = ["ar", "fa", "ur", "ps"];

export default function App() {
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      delay: 250,
      easing: "ease-out-cubic",
    });
    const injectMeta = () => {
      const faviconElement = document.getElementById("favicon");
      const favicon192Element = document.getElementById("favicon192");
      const metaDescription = document.getElementById("description");
      const hreflangElement = document.getElementById("hreflang");
      hreflangElement.href = window.location.origin + window.location.pathname;
      hreflangElement.hreflang = i18n.resolvedLanguage;
      faviconElement.href = faviconUrl;
      favicon192Element.href = favicon192Url;
      metaDescription.content = t("site-description");
      document.documentElement.dir = rtlLanguages.includes(
        i18n.resolvedLanguage
      )
        ? "rtl"
        : "ltr";
    };
    injectMeta();
  });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path="/" element={<Lost />} />
          <Route exact path="/home" element={<Lost />} />
          <Route exact path="/confirmation" element={<Lost />} />
          <Route path={routes.root} element={<ValidateRoutes />} />
          <Route path={routes.home} element={<ValidateRoutes />} />
          <Route path={routes.confirmation} element={<ValidateRoutes />} />
          <Route path={routesBase.root} element={<ValidateRoutes />} />
          <Route path={routesBase.home} element={<ValidateRoutes />} />
          <Route path={routesBase.confirmation} element={<ValidateRoutes />} />
          <Route path="" element={<Lost />} />
          <Route path="*" element={<Lost />} />
        </Route>
      </Routes>
    </Router>
  );
}
