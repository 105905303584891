import React, { useEffect } from "react";

import hero_img from "../images/senior-couple.jpg";

import { useTranslation } from "react-i18next";

import TagManager from "react-gtm-module";

import Footer from "../partials/footer";
import Nav from "../partials/nav";

export default function Home() {
  const { t } = useTranslation();
  const SickUrl = "https://www.washtenaw.org/3104/What-to-do-if-Youre-Sick-or-Exposed"
  const SafeUrl = "https://www.washtenaw.org/3102/Prevention"


  const tagManagerArgs = {
    dataLayer: {
      event: "page_view",
      page_location: document.location.href,
      page_title: t("home-meta-title"),
    },
    };

  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    document.title = t("home-meta-title");
  }, []);
  return (
    <>
    <Nav/>
    <main className="404-page">
          <section data-aos="zoom-y-out" className="hero full-bleed" >
              <div className="container fifty-50 col-2-sm">
                  <div className="section-text">
                      <h1>{t("hero-h")}</h1>
                      <p>{t("404-p")}</p>
                      <br></br>
                      <p>{t("404-p-1")}</p>
                      <br></br>
                      <p>{t("404-p-2")}</p>
                      <br></br>
                     <b><p>{t("404-p-3")}</p></b>

              <ul>
                  <li><a href={SickUrl} target="_blank" rel="noreferrer">{t("404-li1")}</a></li>
                  <li><a href={SafeUrl} target="_blank" rel="noreferrer">{t("404-li2")}</a></li>
              </ul>
                  </div>
                  <div className="section-image">
                      <img src={hero_img} alt="older couple looking at laptop" />
                  </div>
              </div>
          </section>
    </main>
    <Footer/>
    </>
  );
}
