import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";

const Layout = () => {
  const { i18n } = useTranslation();
  let { brand, locale } = useParams();
  useEffect(() => {
    if (locale !== i18n.language) i18n.changeLanguage(locale);
  }, [i18n, locale]);

  return (
    <div
      className={`${brand}-branding language-${i18n.resolvedLanguage}`}
    >
      <Outlet />
    </div>
  );
};

export default Layout;
