import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate, generatePath } from "react-router-dom";
import i18n from "i18next";

import { languages, languageOrdering } from "../i18n/config.js";
import languageNaming from "../i18n/languageNaming.js";
import { usePathPattern } from "../routes";

const LanguageSwitcher = ({ stacked }) => {
  const [isOpen, setIsOpen] = useState(false);
  const pathPattern = usePathPattern();
  const navigate = useNavigate();
  // If none specified, return english
  const { brand = "404", locale = "en" } = useParams();

  const handleTranslation = async (e) => {
    var newLanguage = e.currentTarget.value;
    if (newLanguage !== i18n.resolvedLanguage) {
      await i18n.changeLanguage(newLanguage);
      const path = generatePath(pathPattern, { brand: brand, locale: newLanguage });
      navigate(path);
    }
  };

  const onOpenSelect = () => {
    setIsOpen(true);
  };
  const onCloseSelect = () => {
    setIsOpen(false);
  };

  // Current Langauge Name in english (key of the languageNaming object)
  const currentLanguageName = Object.keys(languages).find(
    (key) => languages[key] === locale
  );
  const currentLanguageLocaleName = languageNaming[currentLanguageName];

  return (
    <label className={`language-switcher ${stacked ? "stacked" : ""}`}>
      {/* <i className="fas fa-language" title="Change Language" /> */}
      <div className="select-container">
        {stacked ? (
          <div className="current-value">{currentLanguageLocaleName}</div>
        ) : null}
        <select
          onChange={handleTranslation}
          onFocus={onOpenSelect}
          onBlur={onCloseSelect}
          value={locale}
        >
          {languageOrdering.map((language) => (
            <option key={language} value={languages[language]}>
              {languageNaming[language]}
            </option>
          ))}
        </select>
        <i className={`select-arrow fas fa-angle-${isOpen ? "up" : "down"}`} />
      </div>
    </label>
  );
};

LanguageSwitcher.defaultProps = {
  stacked: false,
};

LanguageSwitcher.propTypes = {
  stacked: PropTypes.bool,
};

export default LanguageSwitcher;
